import React, {createContext, useCallback, useState} from 'react';

const PopupContext = createContext({
  notification: null,
  notificationText: null,
  success: () => {},
  error: () => {},
  clear: () => {}
});

const PopupProvider = (props) => {
  const [notification, setNotification] = useState(null);
  const [notificationText, setNotificationText] = useState(null);

  const STATES = {
    SUCCESS: 'success',
    ERROR: 'error',
  };

  // Use useCallback to memoize functions and avoid unnecessary re-renders
  const success = useCallback((text) => {
    setNotificationText(text);
    setNotification(STATES.SUCCESS);
  }, [STATES.SUCCESS]);

  const error = useCallback((text) => {
    setNotificationText(text);
    setNotification(STATES.ERROR);
  }, [STATES.ERROR]);

  const clear = useCallback(() => {
    setNotificationText(null);
    setNotification(null);
  }, []);

  return (
    <PopupContext.Provider value={{
      notification, notificationText,
      success, clear, error
    }}>
      {props.children}
    </PopupContext.Provider>
  );
};


export { PopupContext, PopupProvider };
