import React, {createContext, useState, useEffect, useMemo} from 'react';
import {useACFOptions} from "../../_hooks/useACFOptions";

export const LocationContext = createContext({
  location: {
    countryCode: null,
    timezone: null
  },
  isInEU: false,
  isInUSA: false,
  loading: true
});

export const LocationProvider = ({children}) => {
  const [location, setLocation] = useState({
    countryCode: null,
    timezone: null
  });

  const [isInUSA, setIsInUSA] = useState(false);
  const [isInEU, setIsInEU] = useState(false);
  const [loading, setLoading] = useState(true);

  const {quizSettings} = useACFOptions();

  const EUCountryCodes = useMemo(() => {
    return quizSettings?.euRegion.split('/').map(code => code.trim().toUpperCase()) || [];
  }, [quizSettings]);

  const USACountryCodes = useMemo(() => {
    return quizSettings?.usaRegion.split('/').map(code => code.trim().toUpperCase()) || [];
  }, [quizSettings]);

  const key = process.env.GATSBY_IP_API_KEY || '';

  useEffect(() => {
    if (window) {
      if (location?.countryCode !== null && !isInUSA && !isInEU) {
        document.documentElement.classList.add('region-other');
      } else {
        document.documentElement.classList.remove('region-other');
      }
    }
  }, [isInUSA, isInEU, location]);

  useEffect(() => {
    const storedCountryCode = sessionStorage.getItem('countryCode') || null;
    const storedTimezone = sessionStorage.getItem('timezone') || null;

    if (storedCountryCode && storedTimezone) {
      setLocation({
        countryCode: storedCountryCode,
        timezone: storedTimezone
      });
      setIsInEU(EUCountryCodes.includes(storedCountryCode));
      setIsInUSA(USACountryCodes.includes(storedCountryCode));
      setLoading(false);
    } else {
      const getLocationInfo = async () => {
        try {
          const response = await fetch(`https://pro.ip-api.com/json/?key=${key}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });

          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Country code request failed');
          }

          const result = await response.json();

          const countryCode = result.countryCode.toUpperCase();
          const timeZone = result.timezone;

          setLocation({
            countryCode: countryCode,
            timezone: timeZone
          });
          sessionStorage.setItem('countryCode', countryCode);
          sessionStorage.setItem('timezone', timeZone);

          setIsInEU(EUCountryCodes.includes(countryCode));
          setIsInUSA(USACountryCodes.includes(countryCode));
          setLoading(false);
        } catch (error) {
          console.error('Country code request failed: ', error.message);
          setLoading(false);
        }
      };

      getLocationInfo();
    }
  }, []);

  return (
    <LocationContext.Provider value={{location, isInEU, isInUSA, loading}}>
      {children}
    </LocationContext.Provider>
  );
};
