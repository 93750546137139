import React from 'react';
import {UserProvider} from "./_contexts/UserContext";
import {PopupProvider} from "./_contexts/PopupContext";
import {LocationProvider} from "./_contexts/LocationContext";

const RootElement = ({children}) => {
  return (
    <LocationProvider>
      <UserProvider>
        <PopupProvider>
          {children}
        </PopupProvider>
      </UserProvider>
    </LocationProvider>
  );
};

export default RootElement;
