import {graphql, useStaticQuery} from "gatsby";

export const useACFOptions = () => {
  const opts = useStaticQuery(graphql`
    query {
      wp {
        optionsACF {
          optionsGlobalACF {
            quizSettings {
              startDate
              finishDate
              usaRegion
              euRegion
            }
           footer {
              tagline
              copyright
              socials {
                youtube
                instagram
                linkedin
                facebook
              }
           }
           formsTranslations {
              termsConditions {
                title
                url
              }
              privacyPolicy {
                title
                url
              }
           }
          }
        }
      }
    }
  `);
  //
  // const {routed, language} = useI18next();
  // const langSlug = routed ? language : ''
  // const otps = opts?.wp?.optionsACF?.globalOptionsACF || {};

  return opts?.wp?.optionsACF?.optionsGlobalACF || {};
};
