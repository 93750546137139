import React, {createContext, useState, useEffect} from 'react';
import Cookies from "js-cookie";
import {tokenExpiration} from "../../_hooks/utils";

export const UserContext = createContext(null);

export const UserProvider = ({children}) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userVote, setUserVote] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const token = Cookies.get('token');

  useEffect(() => {
    let storedUserData = Cookies.get('user');
    if (storedUserData) {
      setUser(JSON.parse(storedUserData));
    }
    const validateToken = async (token) => {
      let tokenIsValid = Cookies.get('token-is-valid');
      if (token && tokenIsValid === 'true') {
        return;
      }
      try {
        let response = await fetch(`${process.env.GATSBY_WORDPRESS_API_URL}/wp-json/jwt-auth/v1/token/validate`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        response = await response.json();
        if (response.user) {
          setUserData({user: response.user, expires: tokenExpiration(true)});
          Cookies.set('token-is-valid', 'true', {expires: 1});
        } else {
          deleteUserData();
        }
      } catch (error) {
        console.error('Error validating token:', error);
        deleteUserData();
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      validateToken(token);
    } else {
      deleteUserData();
      setLoading(false);
    }
  }, []);

  const deleteUserData = () => {
    setUser(null);
    Cookies.remove('token');
    Cookies.remove('user');
  };

  const setUserData = (data) => {
    if (data?.user) {
      setUser(data.user);
      Cookies.set('user', JSON.stringify(data.user), {expires: data.expires || tokenExpiration()});
    }
  };

  return (
    <UserContext.Provider value={{user, setUser, loading, userVote, setUserVote, showUserMenu, setShowUserMenu, deleteUserData, setUserData}}>
      {children}
    </UserContext.Provider>
  );
};
