import React from "react";

export const noPtag = (str) => {
  if (!str) return '';
  return str.replace(/<p>/g, '').replace(/<\/p>/g, '');
}

export const emailValidation = (email) => {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  return emailRegex.test(email);
}

export const getUserFirstName = (string) => {
  if (!string) {
    return '';
  }
  return string?.split(' ')[0] || '';
}

export const getFormattedDate = (date) => {
  if (!date) return '';
  const inputDate = new Date(date);
  return inputDate.getDate() + ' ' + inputDate.toLocaleString('en-US', { month: 'long' }) + ' ' + inputDate.getFullYear();
}

export const renderPostCategories = (categories) => {
  if (!categories) return '';

  return categories.map(category => {
    return (
      <span className="p-10 bg-lime-green body-small fw-700 text-black" key={category.slug}>{category.name}</span>
    )
  })
}

// https://github.com/js-cookie/js-cookie/wiki/Frequently-Asked-Questions#expire-cookies-in-less-than-a-day
export const tokenExpiration = (rememberMe) => {
  const oneDay = 1;
  const threeDays = 3;
  return rememberMe ? oneDay : threeDays;
}

export const isMobile = () => {
  const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return regex.test(navigator.userAgent);
}
